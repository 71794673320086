<template>
<div v-if="!e404 && product && loaded">
  <SpecialistShopHeader/>
  <div class="my_container">
    <Breadcrumbs>
      <router-link to="/">{{$t('product.home')}}</router-link>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="arr">
        <path d="M10.9605 8.14506C11.0423 8.06543 11.0423 7.93409 10.9605 7.85446L5.32138 2.36015C5.2426 2.28339 5.11702 2.28339 5.03824 2.36014C4.95651 2.43977 4.95651 2.57112 5.03824 2.65075L10.6774 8.14506C10.7562 8.22181 10.8818 8.22181 10.9605 8.14506Z" fill="#000001"/>
        <path d="M5.03824 13.6398C4.95651 13.5602 4.95651 13.4289 5.03824 13.3492L10.6791 7.85331C10.7578 7.77656 10.8834 7.77656 10.9622 7.85331C11.0439 7.93294 11.0439 8.06429 10.9622 8.14391L5.32137 13.6398C5.2426 13.7166 5.11701 13.7166 5.03824 13.6398Z" fill="#000001"/>
      </svg>
      <router-link :to="{name: 'shop', params: {id: userId}}">{{$t('product.shop')}}</router-link>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="arr">
        <path d="M10.9605 8.14506C11.0423 8.06543 11.0423 7.93409 10.9605 7.85446L5.32138 2.36015C5.2426 2.28339 5.11702 2.28339 5.03824 2.36014C4.95651 2.43977 4.95651 2.57112 5.03824 2.65075L10.6774 8.14506C10.7562 8.22181 10.8818 8.22181 10.9605 8.14506Z" fill="#000001"/>
        <path d="M5.03824 13.6398C4.95651 13.5602 4.95651 13.4289 5.03824 13.3492L10.6791 7.85331C10.7578 7.77656 10.8834 7.77656 10.9622 7.85331C11.0439 7.93294 11.0439 8.06429 10.9622 8.14391L5.32137 13.6398C5.2426 13.7166 5.11701 13.7166 5.03824 13.6398Z" fill="#000001"/>
      </svg>
       <a class="curr">{{ `${product.title} - ${product.weight_packaging}` }}</a>
       <template v-slot:title>
        <h2>{{ `${product.title}  ${product.weight_packaging}` }}</h2>
       </template>
    </Breadcrumbs>
  </div>
  <div class="my_container">
    <div class="shop__item__cnt">
      <div class="prod__details">
        <div class="img__cont">
          <div v-if="product[discount]" class="sale">
            {{ product[discount] }}%
          </div>
          <img :src="product.image_thumbnail" alt="">
        </div>
        <div class="col">
          <p class="inner-p" v-html="product.short_description">
           
          </p>
          <div class="prices" v-if="product[inStockProduct]">
            <div v-if="product[oldPrice] > 0" class="old">{{ product[oldPrice] }} {{currency}}</div>
            <div class="current">{{product[price]}} {{currency}}</div>
          </div>
          <div class="prices" v-else>
            <div class="current">
              {{$t('product.inStock')}}
            </div>
          </div>
          <div class="row" v-if="product[inStockProduct]">
            <div class="qty">
              <svg @click="deleteCnt" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="minus">
                <rect x="6" y="11" width="12" height="2" rx="1" fill="#000001"/>
              </svg>
              <div class="count">
                {{ cnt }}
              </div>
              <svg @click="++cnt" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="plus">
                <rect x="6" y="11" width="12" height="2" rx="1" fill="#000001"/>
                <rect x="11" y="6" width="2" height="12" rx="1" fill="#000001"/>
              </svg>
            </div>
            <a class="add__cart" @click="buyItem">
              <span v-if="btnAddTitle">{{ $t('shop.btnAdd') }}</span>
              <span v-else>{{ $t('product.added') }}</span>

              <svg v-if="btnAddTitle" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3 5.25003C2.80109 5.25003 2.61032 5.32905 2.46967 5.4697C2.32902 5.61035 2.25 5.80112 2.25 6.00003C2.25 6.19894 2.32902 6.38971 2.46967 6.53036C2.61032 6.67101 2.80109 6.75003 3 6.75003H4.665L6.633 14.625C6.80025 15.2925 7.398 15.75 8.08575 15.75H17.4382C18.1155 15.75 18.6908 15.3 18.8685 14.6475L20.8125 7.50003H19.242L17.4375 14.25H8.085L6.11775 6.37503C6.03649 6.05195 5.84916 5.76549 5.58576 5.56152C5.32236 5.35754 4.99813 5.24786 4.665 5.25003H3ZM16.5 15.75C15.2662 15.75 14.25 16.7663 14.25 18C14.25 19.2338 15.2662 20.25 16.5 20.25C17.7338 20.25 18.75 19.2338 18.75 18C18.75 16.7663 17.7338 15.75 16.5 15.75ZM9.75 15.75C8.51625 15.75 7.5 16.7663 7.5 18C7.5 19.2338 8.51625 20.25 9.75 20.25C10.9838 20.25 12 19.2338 12 18C12 16.7663 10.9838 15.75 9.75 15.75ZM12 5.25003V7.50003H9.75V9.00003H12V11.25H13.5V9.00003H15.75V7.50003H13.5V5.25003H12ZM9.75 17.25C10.173 17.25 10.5 17.577 10.5 18C10.5 18.423 10.173 18.75 9.75 18.75C9.327 18.75 9 18.423 9 18C9 17.577 9.327 17.25 9.75 17.25ZM16.5 17.25C16.923 17.25 17.25 17.577 17.25 18C17.25 18.423 16.923 18.75 16.5 18.75C16.077 18.75 15.75 18.423 15.75 18C15.75 17.577 16.077 17.25 16.5 17.25Z" fill="white"/>
              </svg>

              <svg v-else class="icon" width="24px" height="24px" fill="#ffffff">
                <use xlink:href="/icons.svg#check"></use>
              </svg>
            </a>
          </div>
          <div class="product__variants-items" v-if="weightPackagingEmtyFilter.length > 1">
            <div class="product__variant"
             v-for="item in weightPackagingEmtyFilter"
             :key="item.id"
             :class="{'active' : item.id == pathId}"
             @click="selectPackaging(item.id)"
            >
              {{item.weight_packaging}}
              <br/>
              <span class="btn_size">{{item.size_packaging}}</span>
            </div>
          </div>
          <div class="size_item" v-if="false && product.size_packaging != ''">
            <svg class="size" width="30px" height="30px" fill="#930e81">
              <use xlink:href="/icons.svg#size"></use>
            </svg>
            <span>{{product.size_packaging}}</span>
          </div>
          <div class="specs">        
            <div class="spec inner-p" v-html="product.ingredients">          
            </div>
          </div>      
        </div>
      </div>
      <div class="prod__benefits">
        <p class="inner-p" v-html="product.description"></p>
        <div class="row">
          <div class="txt inner-p" v-html="product.description2"></div>
          <div v-if="product.image2_thumbnail" class="img__cont">
            <img :src="product.image2_thumbnail" alt="">
          </div>
        </div>
        <div class="row">
          <p style="color:#EB236B">
            {{$t('product.returnPolicy')}}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="my_container" v-if="product.similar_products.length">
    <div class="similar__products__wrp">
      <div class="top">
        <strong>{{$t('product.sliderTitle[0]')}} <b>{{$t('product.sliderTitle[1]')}}</b></strong>
        <div class="arrows">
          <div class="arr prev" @click="$refs.shopItemSleder.prev()">
            <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20.1587 32.5802C19.8317 32.2617 19.8317 31.7363 20.1587 31.4178L42.7153 9.44052C43.0304 9.13352 43.5328 9.13351 43.8479 9.44052C44.1748 9.75903 44.1748 10.2844 43.8479 10.6029L21.2912 32.5802C20.9761 32.8872 20.4738 32.8872 20.1587 32.5802Z" fill="#000001"/>
              <path d="M43.8479 54.5593C44.1748 54.2408 44.1748 53.7154 43.8479 53.3969L21.2846 31.4132C20.9695 31.1062 20.4672 31.1062 20.1521 31.4132C19.8252 31.7317 19.8252 32.2571 20.1521 32.5756L42.7154 54.5593C43.0305 54.8663 43.5328 54.8663 43.8479 54.5593Z" fill="#000001"/>
            </svg>
          </div>
          <div class="arr next" @click="$refs.shopItemSleder.next()">
            <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M43.8413 32.5802C44.1683 32.2617 44.1683 31.7363 43.8413 31.4178L21.2847 9.44052C20.9696 9.13352 20.4672 9.13351 20.1521 9.44052C19.8252 9.75903 19.8252 10.2844 20.1521 10.6029L42.7088 32.5802C43.0239 32.8872 43.5262 32.8872 43.8413 32.5802Z" fill="#000001"/>
              <path d="M20.1521 54.5593C19.8252 54.2408 19.8252 53.7154 20.1521 53.3969L42.7154 31.4132C43.0305 31.1062 43.5328 31.1062 43.8479 31.4132C44.1748 31.7317 44.1748 32.2571 43.8479 32.5756L21.2846 54.5593C20.9695 54.8663 20.4672 54.8663 20.1521 54.5593Z" fill="#000001"/>
            </svg>
          </div>
        </div>
      </div>
      <div class="similar__products__slider">
        <VueSlickCarousel ref="shopItemSleder" :arrows="false" :infinite="false" :slidesToShow="4" :responsive="productsSlider" :slidesToScroll="2" :autoplay="true">
          <div v-for="(item, index) in product.similar_products" :key="index">
            <div class="card" @click="selectNewProduct($event, item.id)" @mousedown="targetCart = $event.x">
              <div class="img__cont">
                <span class="sale" v-if="item[discount]"> {{item[discount]}}% </span>
                <img :src="`https://api.lica.doctor/media/${item.image}`" alt="">
              </div>
              <span class="title">{{item.title}}</span>
              <div class="btm">
                <div class="prices">
                  <div class="old" v-if="item[discount]">{{item[oldPrice]}} {{currency}}</div>
                  <div class="current">{{item[price]}} {{currency}}</div>
                </div>
              </div>
            </div>
          </div>
        </VueSlickCarousel>
        <div ref="vScrollTop" style="dispaly:none" v-scroll-to="{ el: '.img__cont', offset: -64}"></div>
      </div>
    </div>
  </div>
  <FaqWrp/>
  <FooterApp/>
  <!-- modal -->
  <div class="aq__modal__wrp" @click="closeModal" :class="{'active': wrongProductModal}">
    <div class="aq__modal">
      <div class="top">
        <div class="details">
          <!-- <strong>Adaugă înscriere</strong> -->
        </div>
        <div class="close__btn" @click="wrongProductModal = false">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.40294 7.99965L0.503906 14.8987L1.10379 15.4986L8.00282 8.59953L14.9019 15.4986L15.5017 14.8987L8.60271 7.99965L15.501 1.10131L14.9012 0.501431L8.00282 7.39976L1.10449 0.501431L0.504607 1.10131L7.40294 7.99965Z" fill="white"/>
          </svg>
        </div>
      </div>
      <div class="container">
        <!-- <img class="modal_logo" src="/img/logo2.svg"> -->
        <h3 class="title_end">{{wrongProductMsg}}</h3>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs";
import SpecialistShopHeader from "@/components/SpecialistShopHeader"
import FooterApp from "@/components/FooterApp";
import FaqWrp from "@/components/FaqWrp"
import VueSlickCarousel from 'vue-slick-carousel'
import { mapActions, mapGetters } from 'vuex'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import shopMixin from '@/mixins/shop'

const domain = process.env.VUE_APP_BASE_API;

export default {
  mixins: [shopMixin],
  components: {
    SpecialistShopHeader,
    Breadcrumbs,
    FooterApp,
    FaqWrp,
    VueSlickCarousel,
  },
  async created() {
    const visitorCountryCode = await this.getVisitorCountryCode()

    if (!visitorCountryCode) { window.location.href = `/${this.lang}/` }
    if (visitorCountryCode !== 'RO' && visitorCountryCode !== 'MD') { window.location.href = `/${this.lang}/` }

    if ( visitorCountryCode == 'RO' && this.userId == 104 ) {
      window.location.href = `/${this.lang}/product/98/${this.productId}/`
    } else if ( visitorCountryCode == 'MD' && this.userId == 98 ) {
      window.location.href = `/${this.lang}/product/104/${this.productId}/`
    }
    this.loaded = true
  },
  async beforeMount() {
    let user = await this.getUser();
    if(user?.profile?.shop_is_active){
      this.localSpecialist = user.profile.city;
      this.product = await this.getProduct(this.productId);
      if(!this.product || this.product.detail) {
        this.e404 = true;
        this.$router.push({name: "Home", params: { lang: this.lang }});
      }
      else {
        this.sendViewItemEvent({
          item: this.product,
          currency: this.currency,
          price: this.product[this.price]
        })
        this.cnt = this.checkoutCnt;
      }
    }
    else {
      this.$router.push({name: "Home", params: { lang: this.lang }});
    }
  },
  data(){
    return {
      loaded: true,
      product: null,
      e404: false,
      productsSlider: [
        {
          breakpoint: 1800,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 430,
          settings: {
            slidesToScroll: 1,
            slidesToShow: 1,
            dots: true
          }
        }
      ],
      cnt: 1,
      targetCart: 0,
      btnAddTitle: true,
      localSpecialist: "Moldova, Republic of",
      wrongProductModal: false,
      wrongProductMsg: ""
    }
  },
  watch: {
    async lang(){
     this.product = await this.getProduct(this.productId)
    }
  },
  computed: {
    ...mapGetters({
      inCart: 'checkout/inCart',
      getCheckoutItem: 'checkout/item',
      getProductById : 'shopCards/getOneSpecialistPr',
      lang: 'languages/getLang',
      isLogin: 'user/isLogin',
      productsBasket : 'checkout/products'
    }),
    checkoutCnt(){
      let product = this.getCheckoutItem(this.product.id);
      return product ? product.cnt : this.cnt;
    },
    currency(){
      if(this.localSpecialist == "Romania"){
        return "RON";
      }
      else if(this.localSpecialist == "Russian Federation"){
        return "RUB";
      }
      else {
        return "MDL";
      }
    },
    price(){
      if(this.localSpecialist == "Romania"){
        return "client_price_ro";
      }
      else if(this.localSpecialist == "Russian Federation"){
        return "client_price_ru";
      }
      else {
        return "client_price";
      }
    },
    oldPrice(){
      if(this.localSpecialist == "Romania"){
        return "client_price_old_ro";
      }
      else if(this.localSpecialist == "Russian Federation"){
        return "client_price_old_ru";
      }
      else {
        return "client_price_old";
      }
    },
    discount(){
      if(this.localSpecialist == "Romania"){
        return "client_discount_ro";
      }
      else if(this.localSpecialist == "Russian Federation"){
        return "client_discount_ru";
      }
      else {
        return "client_discount";
      }
    },
    inStockProduct(){
      if(this.localSpecialist == "Romania"){
        return "in_stock_for_ro";
      }
      else if(this.localSpecialist == "Russian Federation"){
        return "in_stock_for_ru";
      }
      else {
        return "in_stock_for_md";
      }
    },
    countryCode() {
      if(this.localSpecialist == "Romania"){
        return "ro";
      }
      else if(this.localSpecialist == "Russian Federation"){
        return "ru";
      }
      else {
        return "md";
      }
    },
    productId(){
      return this.$route.params.id;
    },
    userId(){
      return this.$route.params.user;
    },
    weightPackagingEmtyFilter(){
      let flag;
      if(this.localSpecialist == "Romania"){
        flag = "client_in_stock_for_ro"
      }
      else if(this.localSpecialist == "Russian Federation"){
        flag = "client_in_stock_for_ru"
      }
      else {
        flag = "client_in_stock_for_md"
      }
      return this.product.variants_weight_packaging.filter(e => {
        return e.weight_packaging != "" && e[flag];
      });
    },
    pathId(){
      return this.$route.params.id;
    }
  },
  methods: {
    ...mapActions({
      setCnt: 'checkout/setCnt',
      add: 'checkout/add',
      sendAddToCartEvent: 'googleEvents/sendAddToCartEvent',
      sendViewItemEvent: 'googleEvents/sendViewItemEvent'
    }),
    buyItem(e){
     let userId = this.$route.params.user;
     let firstProductId = this.productsBasket[0]?.userId;
     this.sendAddToCartEvent({
      item: this.product,
      currency: this.currency,
      price: this.product[this.price],
      quantity: this.cnt
     })
      let btn = e.target.closest('.add__cart');
      btn.classList.add("eventAddtoCart");
      this.btnAddTitle = false;
      setTimeout(() => {
        btn.classList.remove("eventAddtoCart");
        this.btnAddTitle = true;
      }, 1500);
      
      if(!this.inCart(this.product.id)){
        if(firstProductId == userId || firstProductId == undefined && !this.isLogin){
          this.add({ 'payload': this.product, 'id': this.product.id , 'userId': this.userId });
        }
        else {
          const message = {
            ro: "Acțiunea este limitată! Ați adăugat deja un produs de la un alt specialist sau acest produs este destinat clienților",
            ru: "Действие ограничено! Вы уже добавили в корзину товар от другого специалиста, или данный товар предназначен для клиентов",
            en: "You have already added a product from another specialist to the cart, or this product is intended for customers",
            es: "Ya has añadido un producto de otro especialista al carrito, o este producto está destinado a clientes",

          }
          if(window.innerWidth > 430){
            this.wrongProductMsg = message[this.lang];
            this.wrongProductModal = true;
            setTimeout(() => this.wrongProductModal = false, 4000);
          }
          else {
            alert(message[this.lang]);
          }
        }
      }
      this.setCnt({ id: this.product.id, cnt: this.cnt });
    },
    deleteCnt(){
      if(this.cnt > 1){
        --this.cnt;
      }
      if(this.inCart(this.product.id)) {
        this.setCnt({ id: this.product.id, cnt: this.cnt });
      }
    },
    async getProduct(id){
      try {    
        const request = await fetch(`${domain}/${this.lang}/shop/product/${id}/?active=true&is_active_for_client_${this.countryCode}=true`);
        if(!request.ok){
          throw request;
        }
        const data = await request.json();     
        return {
          ...data,
          discount: data.client_discount,
          discount_ro: data.client_discount_ro,
          discount_ru: data.client_discount_ru,

          in_stock_for_md: data.client_in_stock_for_md,
          in_stock_for_ro: data.client_in_stock_for_ro,
          in_stock_for_ru: data.client_in_stock_for_ru,

          price: data.client_price,
          price_ro: data.client_price_ro,
          price_ru: data.client_price_ru,

          price_old: data.client_price_old,
          price_old_ro: data.client_price_old_ro,
          price_old_ru: data.client_price_old_ru,
        }; 
      }
      catch(er){
        console.error(er);
        this.$router.push({name: "Home"});
      }
    },
    async initProduct(id){
      this.$refs.vScrollTop.click();
      this.product = await this.getProduct(id) //this.getProductById(this.$route.params.id);
      if(!this.product || this.product.detail) {
        this.e404 = true;
        this.$router.push('/');
      }
      else {
        this.cnt = this.checkoutCnt;    
        if(id != this.$route.params.id){
          this.$router.push({name: 'shop-item', params: {'id': id, 'lang': this.lang }});
        }   
      }
    },
    async selectNewProduct(e, id){
      if(this.targetCart == e.x){
        this.initProduct(id);
      }
    },
    async getUser(){
      try {
        let request = await fetch(`${domain}/accounts/users/${this.userId}/`);
        if(!request.ok){
          throw request;
        }
        let res = await request.json();
        return res;
      }
      catch(ex) {
        console.error(ex);
        this.$router.push({name: "Home"});
      }
    },
    selectPackaging(id){
     if(this.pathId != id) {
       this.initProduct(id);
     }
    },

    closeModal(e){
     if(e.target.classList == 'aq__modal__wrp active'){
      this.wrongProductModal = false;
     }
    }
  }
}
</script>

<style lang="scss">
  
  .similar__products__slider .slick-slide {
    margin: 0 !important;
  }
  .similar__products__slider .card {
    max-width: 414px;
    cursor: pointer;
  }
  
  .similar__products__slider .slick-slide {
    display: flex !important;
    justify-content: center;
  }

  .page__breadcrumbs h2 {
    font-size: 25px !important;
  }
  .shop__item__cnt .prod__details .col .specs .spec {
    font-size: 14px !important;
    margin-bottom: 0 !important;
  }
  .shop__item__cnt .prod__benefits .row {
    margin-top: 70px !important;
  }
  .prod__benefits .additionally_item {
    margin-bottom: 20px;
  }
  .shop__item__cnt .title_spec {
    font-size: 16px;
  }
  .prod__benefits .img__cont {

    height: 100%;
    img {
      border-radius: 25px;
    }
  }

  .shop__item__cnt .prod__details .img__cont img {
    border-radius: 20px;
  }

  .similar__products__slider .card .img__cont {
    height: 450px;
  }
  .similar__products__slider .card .title,.similar__products__slider .card .prices {
    cursor: pointer;
  }
  .shop__item__cnt .prod__details .col .add__cart {
    transition: 0.4s;
    &:hover {
      background-color: #9f214e;
    }
    &:active {
      background-color: red;
      transform: rotate(5deg);
    }
  }
  .eventAddtoCart {
    background-color: #14685D !important;
  }

  .shop__item__cnt {
    .product__variants-items {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 29px;
      margin-bottom: 29px;
      border-bottom: 1px solid #e0e0e0;
    }
    .product__variant {
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      transition: .2s ease;
      border: 2px solid #EB236B;
      color: #EB236B;
      border-radius: 5px;
      background-color: #fff;
      cursor: pointer;
      text-align: center;
      padding: 9px 18px 10px;
      transition: 0.4s;
      margin-right: 20px;
      margin-bottom: 15px;
      &.active {
        color: #fff;
        background-color: #EB236B;
        &:hover {
          border-color: #9f214e;
          background-color: #9f214e;
        }
        .btn_size {
          color: #fff;
        }
      }
      .btn_size {
        font-size: 12px;
        color: #930e81;
      }

    }

  }

  .size_item {
    display: flex;
    align-items: center;
    padding-bottom: 25px;
     span {
      margin-left: 15px;
      font-weight: 600;
      color: #930e81;
     }
  }

  @media (max-width: 1200px){
    .similar__products__slider .card {
      max-width: 355px;
    }
  }
  @media (max-width: 1024px){
    .similar__products__slider .card {
      max-width: 315px;
    }
  }
  @media (max-width: 430px){
    .similar__products__slider .card .add__cart {
      padding: 0px 10px !important;
    }
    .similar__products__slider .card {
     max-width: 355px;
     margin: 5px !important;
    }

  }
  @media (max-width: 320px){
   .similar__products__slider .card {
     margin: 5px !important;
   }
   .similar__products__slider .card .add__cart {
     padding: 0px 8px !important;
   }

  .similar__products__slider .card .add__cart span {
    margin-right: 2px !important;
  }

  }
  
</style>


